/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/mixins";

@import "./theme/variables.cotidiano";
@import "node_modules/flag-icon-css/sass/flag-icon.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: AppFont, sans-serif;
  color: var(--ion-color-gray-darkest);
}

app-impressum,
app-tos,
app-privacy {
  ion-header {
    background: var(--ion-color-main);
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: AppFont, sans-serif;
  font-weight: bold;
  margin: 0;
}

ion-content {
  --background: rgba(0, 0, 0, 0);
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.ion-page {
  background: white;
}

h1 {
  font-size: 26px;
}

.place-items-center {
  place-items: center !important;
}

.white {
  fill: white;
  color: white;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.black {
  fill: black;
  color: black;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative;
}

.grey {
  fill: #878787;
  color: #878787;
}

.dark {
  fill: var(--ion-color-black);
  color: var(--ion-color-black);
}

app-venue-suggestion {
  display: block;
}

app-home,
app-home > ion-content > main {
  background-size: cover !important;
  @include desktop {
    background-size: cover !important;
  }
}

app-email-action,
app-email-action > ion-content > main,
app-sign-in,
app-sign-in > ion-content > main,
app-sign-in-order,
app-sign-in-order > ion-content > main,
app-sign-up,
app-sign-up > ion-content > main,
app-maintenance-page,
app-maintenance-page > ion-content > main {
  background: #ffffff;

  @include desktop {
    background: #ffffff !important;
    background-size: cover !important;

    ion-header {
      ion-row {
        background: transparent !important;
      }
    }
  }

  ion-header {
    background: var(--ion-color-main);
    @include desktop {
      background: transparent;
    }
  }
}

app-email-confirmation {
  background: url(#{$brandAssets}/email-confirm.svg) right bottom/35% no-repeat !important;
  @include desktop {
    background: url(#{$brandAssets}/email-confirm.svg) right bottom/15% no-repeat !important;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

app-account {
  ion-header {
    background: var(--ion-color-main);
  }
}

app-payment-success,
app-payment-success > ion-content > main {
  background: white;
}

app-menu,
app-menu > ion-content > main {
  @include desktop {
    background: white !important;
  }
}

app-menu-modal {
  background: #e7f0f1 !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

.card-bg {
  height: 100%;
  background: #f5f5f5 url(/assets/icon/kreditcartepic.svg) right bottom no-repeat !important;
}

.main-color {
  fill: var(--ion-color-main);
  color: var(--ion-color-main);
}

.second-color {
  fill: var(--ion-color-second);
  color: var(--ion-color-second);
}

.gray {
  fill: var(--ion-color-gray-darkest);
  color: var(--ion-color-gray-darkest);
}

.invisible {
  opacity: 0;
}

.flex {
  display: flex;
}

ion-footer {
  background: var(--ion-color-main);
  color: #fff;
  font-family: AppFont-bold, sans-serif;
  font-size: 18px;
  padding: 20px 10px !important;
  transition: 0.3s linear;

  &::before {
    display: none;
  }

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-main);
  }
}

.ion-footer {
  background: var(--ion-color-main);
  color: #fff;
  font-family: AppFont-bold, sans-serif;
  font-size: 18px;
  padding: 20px 10px !important;
  transition: 0.3s linear;

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-second);
  }
}

.ion-footer.invalid {
  background: darkgrey;
  color: grey;

  &:hover {
    background: darkgrey;
    color: grey;
  }
}

.bold {
  font-family: AppFont-bold, sans-serif;
  font-weight: normal;
}

.font-max-bold {
  font-family: AppFont-black, sans-serif;
  font-weight: normal;
}

.drama {
  font-family: Drama, sans-serif;
  font-weight: normal;
}

.menu-row ion-icon .icon-inner svg path {
  fill: red !important;
}

.main-bg.active {
  background: var(--ion-color-main);
}

.second-bg.active {
  background: var(--ion-color-second) !important;
}

.input-group {
  margin-bottom: 10px;
  position: relative;

  .eye-icon {
    position: absolute;
    right: 15px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    z-index: 9999;
  }

  &__label {
    margin: 0 0 5px 2px;
    font-size: 16px;
    color: var(--ion-color-main);
  }

  &__input {
    input {
      color: var(--ion-color-main) !important;
      border-radius: 0px !important;
      background: #f3fafa !important;
      padding: 10px !important;
      font-size: 16px !important;
    }
  }
}

.sc-ion-input-md-h {
  background: #f3fafa !important;
  padding: 10px !important;
}

.padding-auth {
  padding: 30px 26px !important;
  @include desktop {
    padding: 0 !important;
    height: 100%;
  }
}

.auth-wrapper {
  width: 100%;
  @include desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.product-info {
  .action-sheet-group {
    height: 300px;
    padding: 32px 30px !important;

    .action-sheet-title {
      font-family: AppFont-bold, sans-serif;
      color: #333333;
      font-size: 26px;
      padding: 0;

      .action-sheet-sub-title {
        font-family: AppFont, sans-serif;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.auth-container {
  > ion-col {
    display: block;
  }

  // @include desktop {
  //   width: 400px;
  //   margin: 0 auto;
  //   padding: 32px 40px;
  //   background: #ffffff;
  //   border-radius: 0;
  // }
}

ion-fab-button {
  --background: transparent !important;
}

.subway-btn {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 2px solid var(--ion-color-second);
  font-family: AppFont-bold, sans-serif;

  &.filled {
    background: var(--ion-color-second);
    color: #fff;
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: var(--ion-color-second);
    }
  }

  &.flat {
    background: #ffffff;
    color: var(--ion-color-second);
    transition: 0.3s linear;

    &:hover {
      transition: 0.3s linear;
      background: #e3e3e3;
    }
  }
}

.hours-modal {
  @include desktop {
    ion-backdrop {
      background: #000000 !important;
    }
  }
}

.modal-wrapper {
  min-height: 85vh !important;
  @include desktop {
    --width: 450px;
  }
}

.product-modal {
  .modal-wrapper {
    @include desktop {
      width: 450px !important;
      height: auto !important;
    }
  }
}

.hours-modal .modal-wrapper {
  height: calc(100% - 65px);
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    height: 600px;
    width: 480px;
    position: unset;
    bottom: unset;
  }
}

.short-info-modal {
  background: rgba(0, 0, 0, 0.2);
}

.short-info-modal > .modal-wrapper {
  @include desktop {
    width: 350px;
  }
}

.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-main);
}

.ion-color-white {
  color: #fff;
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.shadow-small {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.google-search-input {
  input {
    font-size: 15px !important;
    padding: 20px 0 !important;
  }
}

.menu-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .popover-content {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    @include desktop {
      width: 315px;
      height: auto;
      min-height: auto;
      top: 75px !important;
      left: unset !important;
      right: 12px !important;
    }
  }

  .popover-arrow {
    right: 20px !important;

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.home-popover {
  font-family: AppFont-bold, sans-serif;

  .popover-content {
    width: 285px;
    border-radius: 0;
    left: 50px !important;
    top: 58px !important;
    @include desktop {
      top: 68px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }

  .popover-arrow {
    left: 70px !important;
    top: 50px !important;

    @include desktop {
      left: -220px !important;
      top: 60px !important;
      right: 0;
      margin: auto;
    }

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.step-heading {
  font-family: AppFont-black, sans-serif;
  font-size: 28px;
  text-align: left;
  margin: 10px 0 20px;
}

.small-padding {
  .step-heading {
    font-size: 18px;
    margin: 0 0 25px;
  }
}

.second-bg {
}

.position-relative {
  position: relative !important;
}

.height-auto {
  height: auto !important;
}

.pac-container {
  background: white;
  width: 100%;
  border-radius: 5px;
  padding: 8px 20px !important;
  margin-top: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  border: none !important;

  .pac-item {
    border-top: none !important;
    font-family: AppFont, sans-serif;
    padding: 16px 0;
    line-height: 1 !important;
    font-size: 14px !important;
    border-bottom: 0.5px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }

    .pac-icon {
      display: none !important;
    }

    .pac-matched {
      font-family: AppFont-bold, sans-serif;
    }
  }
}

.preorder-type {
  background: var(--ion-color-second);
  font-size: x-small;
  height: auto;
  margin: 0 0 0 10px;
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
}

ion-radio {
  --color-checked: var(--ion-color-second);
  --color: var(--ion-color-second);
}

ion-checkbox {
  --background-checked: var(--ion-color-second);
  --border-color-checked: var(--ion-color-second);
}

app-toolbar {
  min-height: 70px;
}

app-toolbar-small {
  min-height: 70px;
}

.toolbar-title {
  font-family: AppFont-black, sans-serif;
  padding-left: 65px;
  text-align: left;
}

.pointer {
  cursor: pointer !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
  input {
    cursor: pointer !important;
  }
}

.auth-padding-small {
  padding: 22px 26px;
  position: relative;
  height: 100%;

  @include desktop {
    padding: 0;
  }

  h1 {
    font-size: 26px;
    line-height: 120%;
    margin: 0 0 15px;
  }

  p {
    font-size: 14px;
    line-height: 145%;
    margin: 0;
  }

  .subway-btn {
    position: absolute;
    width: calc(100% - 52px);
    left: 26px;
    bottom: 26px;
    margin: 0;
  }
}

ion-row.center {
  justify-content: center;
  height: 100%;
  place-content: center;
}

ion-item > ion-label {
  white-space: unset !important;
  overflow: unset !important;
}

.split-pane-side {
  max-width: 100% !important;
  flex: auto !important;
  flex-direction: column !important;
}

.cdk-overlay-pane {
  bottom: 0;
}

app-article-option-group-overlay {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #dce9f3 url(#{$brandAssets}/menu_background.png) no-repeat center center/contain;
  background-origin: content-box;
  padding: 16px;

  .wrapper-upgrade {
    height: 750px;
  }
}

.display-contents {
  display: contents;
}

.display-grid {
  display: grid;
}

.label-floating {
  z-index: 4;
}

app-burger-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  cursor: pointer;
}

.underline {
  color: var(--ion-color-gold);
  text-decoration: underline;
}

ion-picker {
  touch-action: none;
}

.cc-animate {
  transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
  transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
  transform: translateY(0) !important;
}

svg {
  width: 100%;
}

.picker-opt {
  opacity: 0.3;
  font-weight: bold;
}

.picker-opt-selected {
  opacity: 1;
}

@include desktop {
  ion-picker-column {
    overflow: auto;
  }
  .picker-opts {
    top: unset !important;
    transform: none !important;
    pointer-events: none;
  }
  .picker-opt {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
  .picker-opt-selected {
    transition: 100ms;
    font-size: 30px;
  }
}

.delivery-not-available-modal .modal-wrapper {
  height: 260px;
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;
  @include desktop {
    height: 220px;
    bottom: unset !important;
  }
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.align-center {
  text-align: center;
}

.searchbar-input.sc-ion-searchbar-md {
  box-shadow: unset !important;
  border: 1px solid #e4eaf0;
  border-radius: 5px !important;
}

ion-header:after {
  display: none;
}

app-my-orders {
  ion-header {
    background: var(--ion-color-main);
  }
}

ion-modal.auto-height {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 95vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}
ion-modal.auto-height-info {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 95vh;

      .modal-content {
        overflow: auto;
      }
    }
    @include mobile {
      width: 80%;
    }
  }
}

ion-modal.align-bottom {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }
}

ion-modal.align-bottom-only-mobile {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    @include desktop {
      bottom: unset !important;
    }
  }
}

ion-modal.allow-gps-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}

.select-venue-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}

.light-grey-transparent {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

ion-item {
  --background-focused-opacity: 0;
}

ion-item {
  --ripple-color: transparent !important;
  --background-hover: transparent !important;
}

app-promo-code {
  display: block;
}

.sidebar {
  ion-slides {
    display: contents;

    > div {
      display: contents !important;
    }
  }

  ion-slide {
    display: contents;
  }
}

.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%;
}

.wpwl-control {
  background: #fafafa;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
}

.wpwl-label {
  width: 100%;
  font-size: 13px;
  padding: 4px 10px;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.cc_dialog {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 16px !important;
  background: #00535f !important;

  p {
    font-size: 10px !important;
  }

  h1 {
    font-size: 16px;
  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.checkbox_cookie_consent {
  display: none;
}

.cc_cp_f_save {
  button {
    background: white !important;
  }
}

.cc_b_cp {
  color: #00535f !important;
  background: white !important;
  border: 2px solid white !important;
}

.cc_b_ok {
  color: white !important;
  border: 2px solid white !important;
  background-color: #00535f !important;
}

app-add-to-home {
  position: absolute;
  bottom: 0;
}

ion-row.center-vertical {
  > * {
    align-self: center;
  }
}

.dashed-border {
  border: 2px dashed var(--ion-color-main);
}

.tip-picker {
  .picker-columns {
    width: 30%;
    min-width: 230px !important;
    margin: 0 auto;

    ion-picker-column > .picker-opts > .picker-opt-selected {
      font-size: 25px;
      color: var(--ion-color-primary);
    }

    .picker-col {
      min-width: 45px !important;
    }

    .sign-column {
      width: 30px;
    }

    .comma-column {
      width: 30px;
    }
  }
}

.padding-5 {
  padding: 5px;
}

.max-height-100 {
  max-height: 100%;
}

.content-only {
  color: var(--ion-color-primary);
  width: auto;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0 5px;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
  --background: none;
}

.only-next-day-modal > .modal-wrapper {
  max-width: 350px;
}

.reg-modal > .modal-wrapper {
  max-width: 350px;
}

.map-modal {
  .modal-wrapper {
    width: 85%;
    height: 80vh;
    @include desktop {
      width: 100%;
      height: 100vh;
    }
  }
}

.working-hours {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

  ion-backdrop {
    background: #000000 !important;
  }
}

.ion-intl-tel-input-code {
  padding: 0px !important;
  height: 100% !important;
  ionic-selectable {
    padding: 0px !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px !important;
    background: #f3fafa !important;
    padding-left: 25px !important;
  }
}

ionic-selectable-modal {
  .searchbar-clear-icon {
    display: none;
  }
}

.ionic-tel-input-modal {
  ion-backdrop {
    background: rgba(5, 5, 5, 0.35);
    opacity: 0.5 !important;
    width: 100%;
    height: 100%;
  }
  .modal-wrapper {
    height: 62vh;
    width: 85% !important;
    border-radius: 8px;
    @media (max-width: 400px) {
      width: 85% !important;
    }
    @include desktop {
      width: 50%;
    }
    ion-icon {
      margin: 0px 10px !important;
    }
    span {
      font-size: 12px;
    }
    .searchbar-search-icon {
      margin: 0px 25px 0px 10px !important;
    }
    .searchbar-input {
      padding-inline-start: 50px !important;
    }
    .button-clear {
      color: transparent;
      font-family: AppFontBold, sans-serif;
      text-transform: uppercase;
      margin: 10px;
      width: 100px;
      border-radius: 5px;
      min-height: 40px;
      --max-height: 30px !important;
      background: transparent;
      position: relative;

      &::after {
        content: "";
        background: url(./assets/cotidiano/cross_button.svg) no-repeat;
        position: absolute;
        height: 20px;
        width: 20px;
        top: 55%;
        left: 87%;
        transform: translate(-50%, -50%);
      }
    }
    .title-default {
      display: none;
    }
  }
}
