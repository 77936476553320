@import '~@angular/material/theming';

@include mat-core();

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
  --brand: 'selforder';
  /** primary **/
  --ion-color-primary: var(--ion-color-main);
  --ion-color-primary-rgb: var(--ion-color-main-rgb);
  --ion-color-primary-contrast: var(--ion-color-main-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-main-shade);
  --ion-color-primary-tint: var(--ion-color-main-tint);

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** payment-success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-main: #96C11F;
  --ion-color-main-rgb: 0, 137, 56;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255, 255, 255;
  --ion-color-main-shade: #008938;
  --ion-color-main-tint: #008938;

  --ion-color-second: #E41375;
  --ion-color-second-rgb: 242, 183, 0;
  --ion-color-second-contrast: #ffffff;
  --ion-color-second-contrast-rgb: 255, 255, 255;
  --ion-color-second-shade: #F2B700;
  --ion-color-second-tint: #F2B700;


  --ion-color-gray-light: #F5F5F5;
  --ion-color-gray-dark: #D4D4D4;
  --ion-color-gray-darkest: #1F1B1C;

  --ion-color-black: #012734;
  --ion-color-gold: #A38A5F;

  --ion-default-font: AppFont, serif !important;
  --ion-text-color: var(--ion-color-black);
}

.ion-color-main-color {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-second-color {
  --ion-color-base: var(--ion-color-second);
  --ion-color-base-rgb: var(--ion-color-second-rgb);
  --ion-color-contrast: var(--ion-color-second-contrast);
  --ion-color-contrast-rgb: var(--ion-color-second-contrast-rgb);
  --ion-color-shade: var(--ion-color-second-shade);
  --ion-color-tint: var(--ion-color-second-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

html, body {
  height: 100%;
  font-family: VisiblyRound, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
}

