@import "../theme/mixins";

$brand: 'cotidiano';
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #012734;
  --ion-color-second: #A38A5F;
  --payment-modal-icon-size: 54px;
}

@font-face {
  font-family: AppFont;
  src: url(../assets/fonts/texta_alt.otf);
}

@font-face {
  font-family: AppFont-black;
  src: url(../assets/fonts/texta_alt_black.otf);
}

@font-face {
  font-family: Drama;
  src: url(../assets/fonts/drama.otf);
}

@font-face {
  font-family: AppFont-bold;
  src: url(../assets/fonts/courier_new_bold.ttf);
}

app-home,
app-home > ion-content > main {
  background: #E7F0F1 !important;
}
